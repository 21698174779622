import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { Box, Typography } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import { CustomAvatar } from '../../../components/custom-avatar';
// ----------------------------------------------------------------------
const StyledRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));
// ----------------------------------------------------------------------
export default function NavAccount() {
    const { user } = useAuthContext();
    const theme = useTheme();
    const hasSuperAdminRole = user?.roles.find((role) => role?.code === 'SUPER_ADMIN');
    const currentRole = hasSuperAdminRole ? 'SUPER_ADMIN' : user?.roles[0].code;
    return (_jsxs(StyledRoot, { children: [_jsx(CustomAvatar, { src: user?.avatar, alt: `${user?.name} ${user?.lastName}`, name: `${user?.name} ${user?.lastName}`, sx: { background: theme.palette.primary.main } }), _jsxs(Box, { sx: { ml: 2, minWidth: 0 }, children: [_jsx(Typography, { variant: "subtitle2", noWrap: true, sx: { textTransform: 'capitalize' }, children: `${user?.name} ${user?.lastName}` }), _jsx(Typography, { variant: "body2", noWrap: true, sx: { color: 'text.secondary' }, children: currentRole })] })] }));
}
